import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import './SetupSlider.scss'
import { setup1New, setup2New, setup3New, setupgraph } from '../../assets/images'   
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { withTranslation } from 'react-i18next';


class SetupSlider extends Component {
    state = {
        activeSlide: 0,
    }
    render() {
        const settings = {
            dots: true,

            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            infinite: false,
            afterChange: current => this.setState({ activeSlide: current })
        };

        let { t } = this.props

        return (
            <div className="setup-slider pt-0 pt-md-5">
                <Slider {...settings}>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <p className="pb-0 pb-md-3 pt-1 pt-md-5"><span>1. </span>Click the Control Button 5x to turn on your device</p>
                        </div>
                        <div className="setup-slider__content-img">
                            <img src={setup1New} />
                        </div>
                    </div>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <img src={setupgraph} />
                            <p className="pb-0 pb-md-3 pt-2 pt-md-5"><span>2. </span>Cycle through Smart Paths by pressing the up/down buttons to select your desired Smart Path.</p>

                        </div>
                        <div className="setup-slider__content-img">
                            <img src={setup2New} />
                        </div>
                    </div>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <p className="pb-3 pt-2 pt-md-5"><span>3. </span>To activate Precision temperature Mode, click the Control Button 1x.</p>
                            <p> <span>5. </span>Set your desired temperature by using the up/down buttons</p>
                        </div>
                        <div className="setup-slider__content-img">
                            <img src={setup3New} />
                        </div>
                    </div>
                </Slider>
                <button className="btn btn-primary mt-md-2 mt-1 " style={{ color: "#fff" }}>
                    <Link className="btn-link" to="/home">{t("continue")}</Link>
                </button>
            </div>
        );
    }
}

export default (withTranslation()(SetupSlider))